<template>
  <div>
    <van-popup
      v-model="showSlide"
      :close-on-click-overlay="false"
      position="left"
      style="width: 325px; height: 100%"
    >
      <div class="slidePop">
        <div class="header">
          <van-icon
            name="cross"
            color="#fff"
            size="20px"
            @click="closeSlide()"
            class="closeIcon"
          />
          <img src="@/assets/images/logo.svg" alt="" class="ripe" />
        </div>
        <div class="userBox">
          <div class="avatarBox" @click="goPage('/myInfo')">
            <img
              :src="userInfo.headImg"
              style="width: 78px; height: 78px"
              alt=""
            />
            <div class="level">{{ userInfo.vipLevel || 1 }}</div>
          </div>
          <div class="name">{{ userInfo.userName }}</div>
          <div class="invite" @click="copyed(userInfo.inviteCode)">
            Invitation Code:{{ userInfo.inviteCode }}
          </div>
        </div>
        <div class="progressBox">
          <span>Credibility Value:</span>
          <div>
            <van-progress
              :percentage="parseFloat(userInfo.creditScore || 100)"
              stroke-width="3"
              color="#0FD0B1"
            />
          </div>
        </div>
        <div class="infoBox">
          <div class="info">
            <p>USD {{ userInfo.todayProfit }}</p>
            <p>Today profit</p>
            <p class="tips">The system automatcally update the daily earnings</p>
          </div>
          <div class="info">
            <p style="margin-top: 20px">USD {{ userInfo.walletAmount }}</p>
            <p>Wallet Amount</p>
          </div>
        </div>
        <div class="buttonBox">
          <div class="button" @click="goPage('/deposit')">
            <img src="@/assets/images/slide1.png" alt="" />
            <span>Deposit</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="goPage('/withdraw')">
            <img src="@/assets/images/slide2.png" alt="" />
            <span>Withdraw</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="goPage('/myInfo')">
            <img src="@/assets/images/slide3.png" alt="" />
            <span>Personal Info</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="goPage('/transaction')">
            <img src="@/assets/images/slide4.png" alt="" />
            <span>Transaction</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="bindWallet()">
            <img src="@/assets/images/slide5.png" alt="" />
            <span>Wallet Usd Bind</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="showSheet = true">
            <img src="@/assets/images/slide6.png" alt="" />
            <span>Customer Care</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
          <div class="button" @click="getout()">
            <img src="@/assets/images/slide7.png" alt="" />
            <span>Account Logout</span>
            <van-icon name="arrow" color="#24AEC3" size="18px" />
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showPop" round position="bottom">
      <div class="setPwd">
        <h2>
          Please set the Security PIN
          <van-icon
            name="cross"
            @click="showPop = false"
            class="iconClose"
            color="#666"
          />
        </h2>
        <div class="line">
          <span>Security PIN</span>
          <div class="inputBox">
            <input
              :type="form.isSeePin ? 'text' : 'password'"
              class="input"
              placeholder="Type Password"
              v-model="form.pin"
            />
          </div>
          <img
            src="@/assets/images/see.png"
            @click="form.isSeePin = false"
            v-if="form.isSeePin"
            style="width: 30px"
            alt=""
          />
          <img
            src="@/assets/images/seeOff.png"
            @click="form.isSeePin = true"
            v-if="!form.isSeePin"
            style="width: 30px"
            alt=""
          />
        </div>
        <div class="line">
          <span>Confirm Security PIN</span>
          <div class="inputBox">
            <input
              :type="form.isSeeRePin ? 'text' : 'password'"
              class="input"
              placeholder="Type Password"
              v-model="form.rePin"
            />
          </div>
          <img
            src="@/assets/images/see.png"
            @click="form.isSeeRePin = false"
            v-if="form.isSeeRePin"
            style="width: 30px"
            alt=""
          />
          <img
            src="@/assets/images/seeOff.png"
            @click="form.isSeeRePin = true"
            v-if="!form.isSeeRePin"
            style="width: 30px"
            alt=""
          />
        </div>
        <div class="df_btn" @click="submitData()">Submit</div>
      </div>
    </van-popup>
    <van-dialog
      width="90%"
      v-model="showDialog"
      title=""
      class="walletBox"
      :showConfirmButton="false"
    >
      <h2>
        <span>Wallet Usd Bind</span>
        <img
          src="@/assets/images/close2.png"
          class="close"
          @click="showDialog = false"
          alt=""
        />
      </h2>
      <p>Usd address:(TRC-20/ERC-20)</p>
      <div class="textareaBox">
        <textarea
          placeholder="Please enter the Usd address!"
          v-model="usdtAddress"
        />
      </div>
      <div class="df_btn" @click="saveAddress()">Save</div>
    </van-dialog>
    <van-action-sheet
      v-model="showSheet"
      :actions="actions"
      cancel-text="Cancel"
      close-on-click-action
      @select="selectSheet"
      @cancel="showSheet = false"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      showDialog: false,
      showPop: false,
      showSlide: false,
      isSetPwd: false, //是否设置了PIN密码
      userInfo: {},
      form: {
        pin: "",
        rePin: "",
        isSeePin: false,
        isSeeRePin: false,
      },
      usdtAddress: "",
      showSheet: false,
      actions: [{ name: "Ripemedia CS" }],
    };
  },
  methods: {
    copyed(value) {
      var input = document.createElement("input"); // 创建input对象
      input.value = value; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.toast("Copy Successfully");
    },
    //客服
    selectSheet(opt) {
      window.open(opt.value, "_blank");
    },
    async saveAddress() {
      if (!this.usdtAddress) {
        this.showInfo("Information cannot be empty");
        return;
      }
      const res = await this.getAjax(
        "api/zopuwmbfapsnb/bbywisnzjawm/setUsdtAddress",
        {
          usdtAddress: this.usdtAddress,
        },
        "post"
      );
      if (res.success) {
        this.showInfo("Successfully saved");
        this.showDialog = false;
        this.usdtAddress = "";
      }
    },
    submitData() {
      if (!this.form.pin || !this.form.rePin) {
        this.showInfo("Please fill in the complete information");
        return;
      }
      if (this.form.pin !== this.form.rePin) {
        this.showInfo("Password inconsistency");
        return;
      }
      this.getAjax(
        "api/zopuwmbfapsnb/bbywisnzjawm/setSecPwd",
        {
          secPwd: this.form.pin,
        },
        "post"
      ).then((res) => {
        this.showInfo("Successfully set");
        this.showPop = false;
        this.isSetPwd = true;
      });
    },
    async bindWallet() {
      //查询是否设置了提款密码
      const res = await this.getAjax("api/zopuwmbfapsnb/bbywisnzjawm/isSetSecPwd", {}, "get");
      this.isSetPwd = res.data === "Not" ? false : true;
      if (this.isSetPwd) {
        this.usdtAddress = res.data;
        this.showDialog = true;
      } else {
        this.showPop = true;
      }
    },
    getout() {
      this.confirm(
        "Are you sure you want to log out of this account?",
        () => {
          sessionStorage.removeItem("slide");
          this.$router.push("/login");
        },
        null,
        {
          title: "Tips",
          confirmButtonText: "Logout",
          confirmButtonColor: "#26AAC3",
          cancelButtonText: "Cancel",
        }
      );
    },
    goPage(page) {
      this.$router.push(page);
    },
    closeSlide() {
      this.showSlide = false;
      sessionStorage.removeItem("slide");
    },
    async initPage() {
      this.getAjax("api/zopuwmbfapsnb/bbywisnzjawm/getBalance", {}, "get").then((res) => {
        this.userInfo = res.data;
      });

      //获取客服列表
      const res2 = await this.getAjax(
        "api/ahsuoapiemla/bbshwuzma/getCustomer",
        {},
        "get"
      );
      this.actions = res2.data.filter((e) => !!e.name);
    },
  },
  created() {
    if (sessionStorage.getItem("slide")) {
      this.showSlide = true;
    }
  },
  mounted() {
    this.initPage();
  },
};
</script>

<style lang="less" scoped>
/deep/ .van-dialog .van-dialog__footer {
  border-top: 0;
}
.walletBox {
  padding: 10px;
  h2 {
    color: #70798a;
    text-align: center;
    position: relative;
    span {
      font-size: 18px;
    }
    .close {
      width: 13px;
      position: absolute;
      right: 10px;
      top: 7px;
    }
  }
  p {
    font-size: 12px;
    color: #22b0c6;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .textareaBox {
    height: 80px;
    padding: 10px;
    border: 2px solid #c4e8ef;
    border-radius: 5px;
    box-sizing: border-box;
    textarea {
      width: 100%;
      height: 100%;
      resize: none;
    }
  }
  .df_btn {
    margin: 20px auto 0;
  }
}
.setPwd {
  padding: 15px;
  h2 {
    font-size: 15px;
    position: relative;
    color: #22b0c6;
    margin-bottom: 40px;
    .iconClose {
      position: absolute;
      right: 0;
      top: 4px;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .line {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #777;
    border: 2px solid #c4e8ef;
    border-radius: 5px;
    margin-top: 20px;
    padding: 0 5px 0 10px;
    span {
      font-size: 14px;
      color: #22b0c6;
    }
    .inputBox {
      flex: 1;
      padding-right: 10px;

      input {
        text-align: right;
        width: 100%;
      }
    }
  }
  .df_btn {
    height: 40px;
    line-height: 38px;
    margin: 20px auto 10px;
  }
}
::-webkit-scrollbar {
  display: none;
}
.slidePop {
  .header {
    width: 100%;
    height: 190px;
    background: url("@/assets/images/nav_bg.png") 0 -30px no-repeat;
    background-size: cover;
    padding: 10px;
    position: relative;
    .closeIcon {
      position: absolute;
      top: 10px;
      left: 15px;
    }
    .ripe {
      width:150px;
      display: block;
      margin: 0 auto;
    }

    p {
      font-size: 12px;
      color: #97bec3;
      padding: 0 10px;
    }
  }
  .userBox {
    margin-top: -117px;
    position: relative;
    .avatarBox {
      width: 82px;
      height: 82px;
      background: url("@/assets/images/slide_head_bg.png") no-repeat;
      background-size: cover;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        border-radius: 100px;
      }
      .level {
        width: 35px;
        height: 30px;
        background: url("@/assets/images/slideLevel.png") no-repeat;
        background-size: cover;
        position: absolute;
        right: -30px;
        top: -10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        color: #21b5bf;
      }
    }
    .name {
      font-size: 16px;
      text-align: center;
      font-weight: bold;
      color: #000;
      margin-top: 5px;
    }
    .invite {
      font-size: 12px;
      color: #fff;
      height: 24px;
      line-height: 22px;
      text-align: center;
      background: #1ec0b9;
      border-radius: 5px;
      margin: 10px auto;
      display: block;
      padding: 0 5px;
      width: 160px;
    }
  }
  .progressBox {
    display: flex;
    align-items: center;
    padding: 0 15px;
    margin-top: 20px;
    span {
      font-size: 12px;
      color: #289ec9;
      margin-right: 5px;
    }
    div {
      flex: 1;
    }
  }
  .infoBox {
    margin-top: 15px;
    display: flex;
    padding-left: 15px;
    .info {
      flex: 1;
      flex-grow: 1;
      height: 90px;
      background-image: linear-gradient(to bottom, #22acc3, #18c7b9);
      margin-right: 15px;
      border-radius: 5px;
      color: #fff;
      text-align: center;
      padding-top: 5px;
      box-shadow: 3px 5px 0px #c0f6d7;
      p {
        font-size: 13px;
        margin-top: 5px;
        font-weight: bold;
      }
      .tips {
        font-size: 12px;
        transform: scale(0.8);
        width: 120%;
        text-align: left;
        margin-left: -7px;
        font-weight: normal;
      }
    }
  }
  .buttonBox {
    padding: 0 10px;
    margin-top: 20px;
    .button {
      height: 54px;
      border-bottom: 2px solid #ddf5f4;
      display: flex;
      align-items: center;
      img {
        width: 30px;
        margin: 0 10px 0 5px;
      }
      span {
        flex: 1;
        color: #777;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>